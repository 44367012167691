import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";
import { useConfig } from "../../../api/config";
import { useApi } from "../../../functionLib/hooks";
import ButtonComponent from "../../UIComponents/ButtonComponent/ButtonComponent";
import TitledInput from "../../UIComponents/TitledInputTextComponent/TitledInputTextComponent";
import new_logo from "../../../assets/img/logo.png";
import "./LoginComponent.css";

const LoginComponent = () => {
  const api = useApi();
  const config = useConfig();
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState({
    data: "",
    name: "",
    borderColor: "",
  });

  const checkIfCustomerLoggedIn = async () => {
    try {
      const result = await api.authorize.checkIfLoggedIn();
      if (result.loggedIn) {
        window.location = "/v2/bots";
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkIfCustomerLoggedIn();
  }, []);

  // Calls on InputPopupComponent closing
  const onSetShow = () => {
    setMessage({
      ...message,
      name: "",
    });
  };

  // Create message for error notifications in input fields and set border color for input
  const createMessage = (messageText, name) => {
    setMessage({
      ...message,
      data: messageText,
      name,
    });
  };

  const onLogin = (by = "email") => {
    switch (by) {
      case "email":
        api.authorize
          .email(email, password)
          .then((result) => {
            /**
             * Example of successfull response:
             * @returns {status: "success", correct: true, customerID: "3d83d7a4b482"}
             */
            if (result.correct) {
              setRedirect(true);
            } else {
              /**
               * TODO: Here should be handled cases with uncorrect data
               * Invalid email address, incorrect password etc.
               */
              window.notification.error(
                "Incorrect login or password. Please try again.",
                2
              );
            }
          })
          .catch((error) => {
            /**
             * TODO: Here should be handled cases with bad server response
             * 502 from back-end etc, timeout for request etc.
             */

            window.notification.error(error.message, 5);
          });
        break;
      default:
        console.error("Choose one of the valid authorization cases");
        window.notification.error(
          "Choose one of the valid authorization cases",
          5
        );

        break;
    }
  };
  // Function to check user input fields
  const onDisable = () => {
    if (!email) {
      createMessage("Please fill out this field", "email");
    } else if (!password) {
      createMessage("Please fill out this field", "password");
    }
  };

  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Enter") {
        document.getElementById("loginButton")?.click();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return redirect ? (
    <Redirect to="/bots" />
  ) : (
    <div className="LoginComponent_main">
      <header>
        <img
          src={
            config.BRAND_IMAGE_LINK === "activechat"
              ? new_logo
              : config.BRAND_IMAGE_LINK
          }
          alt=""
        />
      </header>
      <form className="CreateAccount_container">
        <div className="CreateAccount_main">
          <h2 className="CreateAccount_title_header">Welcome back!</h2>
          <div className="CreateAccount_title_signup">
            Don't have an account yet?{" "}
            <div className="CreateAccount_title_signup">
              Need another account? <Link to="/signup">Sign up!</Link>
            </div>
          </div>

          <div className="emailInputContainer">
            <TitledInput
              value={email}
              title="Email"
              onChange={(e) => {
                setEmail(e.target.value);
                createMessage("", "");
              }}
              placeholder="example@email.com"
              type="email"
            />
          </div>
          <div className="emailInputContainer">
            <TitledInput
              title="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                createMessage("", "");
              }}
              placeholder="Enter your password"
              type="password"
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Link to="/reset">Forgot password?</Link>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ButtonComponent
              id="loginButton"
              className="login"
              onClick={() => onLogin("email")}
              locked={!(email && password)}
              onDisableClick={onDisable}
              acuikit
            >
              Login
            </ButtonComponent>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginComponent;
