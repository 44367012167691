import React, { useEffect, useState } from "react";
import { useAppState } from "../../../../engine/globalStateContext";
import { useApi, useStateCallBack } from "../../../../functionLib/hooks";
import svgLib from "../../../../functionLib/svglib";
import { useN11s } from "../../../../hooks/notifications/actions";
import { useAppBot } from "../../../../redux/bot/bot.selectors";
import { BotAgentType } from "../../../../types/agent";
import ButtonComponent from "../../../UIComponents/ButtonComponent/ButtonComponent";
import Select from "../../../UIComponents/Select/Select";
import TitledInputTextComponent from "../../../UIComponents/TitledInputTextComponent/TitledInputTextComponent";
import {
  mainLangList,
  secondaryLangList,
} from "../BuildYourFirstBot/botsLanguageArrays";
import { copyToNewAgent, createNewAgentFromScratch } from "./api";
import { StateSetter } from "./types";

export type Step1Props = {
  currentStep: number;
  copyBot: boolean;
  setStep: StateSetter<number>;
  setLoading: StateSetter<boolean>;
  setNewBotId: StateSetter<string>;
};
export const Step1: React.FC<Step1Props> = ({
  currentStep,
  copyBot,
  setStep,
  setLoading,
  setNewBotId,
}) => {
  const api = useApi();
  const [{ popup }] = useAppState();
  const { botAgent: selectedBot } = useAppBot();
  const [lang, setLang] = useState("en");
  const [copyBotId, setCopyBotId] = useState<string>();
  const [botName, setBotName] = useState("My bot");
  const [botList, setBotList] = useState<BotAgentType[]>([]);
  const n11s = useN11s();

  const [botSettingsData, setBotSettingsData] = useState({
    isSaved: true,
    title: selectedBot?.title,
    bot_img: selectedBot?.image,
  });
  const [resizedImage, setResizedImage] = useState({
    image: "",
    file: "",
    url: "",
  });
  const [rawImage, setRawImage] = useState<string | ArrayBuffer>();
  const [originalImage, setOriginalImage] = useStateCallBack(
    { image: "", file: "", url: "" },
    () =>
      setBotSettingsData({
        ...botSettingsData,
        isSaved: false,
        bot_img: originalImage.image,
      })
  );

  const getBots = async () => {
    try {
      const { agents } = await api.agents.get(
        { limit: 100 },
        { title: true, _id: true }
      );
      setCopyBotId(agents[0]._id);
      setBotList(agents);
    } catch (error) {
      n11s.error(error.message, 5);
    }
  };
  useEffect(() => {
    if (copyBot) {
      getBots();
    }
  }, []);

  const onChangeFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();

    const file = e.target.files[0];
    if (!file) {
      return;
    }
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (!reader.result) {
        return;
      }
      setRawImage(reader.result);
      popup.crop(reader.result as string, (data) => {
        setOriginalImage({
          ...originalImage,
          image: data.original.image,
          file: data.original.file,
        });

        setResizedImage({
          ...resizedImage,
          image: data.resized.image,
          file: data.resized.file as any,
        });
      });
    };
  };
  const editImage = () => {
    popup.crop(rawImage as any, (data) => {
      setOriginalImage({
        ...originalImage,
        image: data.original.image,
        file: data.original.file,
      });

      setResizedImage({
        ...resizedImage,
        image: data.resized.image,
        file: data.resized.file as any,
      });
    });
  };

  const deleteImage = () => {
    setResizedImage({
      image: "",
      file: "",
      url: "",
    });
    setRawImage(undefined);
    setOriginalImage({
      image: "",
      file: "",
      url: "",
    });
  };
  const saveAndNext = async () => {
    if (!copyBot) {
      try {
        setLoading(true);
        await createNewAgentFromScratch(
          api,
          setNewBotId,
          botName,
          lang,
          originalImage
        );
        setLoading(false);
        setStep((pre) => Math.min(2, pre + 1));
      } catch (error) {
        setLoading(false);
        n11s.error(error.message, 5);
      }
    } else if (copyBotId) {
      try {
        setLoading(true);
        await copyToNewAgent(
          api,
          setNewBotId,
          botName,
          lang,
          originalImage,
          copyBotId
        );
        setLoading(false);
        setStep((pre) => Math.min(2, pre + 1));
      } catch (error) {
        setLoading(false);
        n11s.error(error.message, 5);
      }
    }
  };
  return (
    <>
      <div className="new_bot_body_step_1">
        <div className="new_bot_body_image_upload">
          <div>
            {!botSettingsData.bot_img ? (
              <label className="" htmlFor="bot_img">
                <div className="new_bot_body_image_upload_area">
                  <div className="new_bot_body_image_upload_area_icon">
                    {svgLib.image_icon()}
                  </div>
                  <div className="new_bot_body_image_upload_area_text">
                    <p>Click to upload the image</p>
                    <p>You can do it later</p>
                  </div>
                </div>
                <input
                  id="bot_img"
                  accept=".jpg, .jpeg, .png"
                  name="bot_img"
                  // className="fileInput"
                  type="file"
                  multiple={false}
                  onInput={onChangeFile}
                  hidden
                />
              </label>
            ) : (
              <div className="new_bot_body_image_editor">
                <div className="new_bot_body_image">
                  <img
                    style={{
                      height: botSettingsData.bot_img ? "100%" : "60%",
                    }}
                    src={botSettingsData.bot_img}
                    alt=""
                  />
                </div>
                <div className="new_bot_body_image_controls">
                  <label className="" htmlFor="bot_img">
                    <div className="new_bot_body_image_controls_item">
                      {svgLib.upload_cloud("#B0B0B0")}
                    </div>
                    <input
                      id="bot_img"
                      accept=".jpg, .jpeg, .png"
                      name="bot_img"
                      // className="fileInput"
                      type="file"
                      multiple={false}
                      onInput={onChangeFile}
                      hidden
                    />
                  </label>
                  <div
                    className="new_bot_body_image_controls_item"
                    onClick={() => editImage()}
                  >
                    {svgLib.pen("#B0B0B0")}
                  </div>
                  <div
                    className="new_bot_body_image_controls_item"
                    onClick={() => deleteImage()}
                  >
                    {svgLib.trash("#B0B0B0")}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {
          // /////////// INPUT
        }
        <div className="new_bot_body_inputs">
          <div className="new_bot_body_input new_bot_body_input_name">
            <TitledInputTextComponent
              style={{ margin: "0", width: "100%" }}
              title="Bot name"
              placeholder="Bot name"
              name="title"
              value={botName}
              type="text"
              onChange={(e) => setBotName(e.target.value)}
              maxLength={29}
            />
          </div>
          <div className="new_bot_body_input new_bot_body_input_lang">
            <Select
              title="Bot language"
              acuikit
              onChange={(value) => setLang(value)}
              value={lang || "en"}
              options={[...mainLangList, ...secondaryLangList].map((item) => ({
                content: (
                  <div className="group_bot_select_option">{item.native}</div>
                ),
                value: item.code,
                name: item.native,
              }))}
            />
          </div>
          {/* <div className="new_bot_lang_desc">
            {mainLangList.some((item) => item.code === lang) ? (
              <span>
                This language has full AI / NLP support with Intents and AI
                hints
              </span>
            ) : (
              <span>
                This language is partially supported. Natural language
                understanding (Intents) will work, but Insights and AI hints
                will not be available
              </span>
            )}
          </div> */}
          {copyBot && (
            <div className="new_bot_body_input new_bot_body_input_bots">
              <Select
                title="The bot to be copied"
                acuikit
                onChange={(value) => setCopyBotId(value)}
                value={copyBotId || ""}
                options={botList.map((item) => ({
                  content: (
                    <div className="group_bot_select_option">{item.title}</div>
                  ),
                  value: item._id,
                  name: item.title,
                }))}
              />
            </div>
          )}
        </div>
      </div>
      <div className="new_bot_footer">
        <ButtonComponent
          className="new_bot_footer_button"
          acuikit
          locked={currentStep === 0}
          onClick={() => setStep((pre) => Math.max(0, pre - 1))}
        >
          Back
        </ButtonComponent>
        <ButtonComponent
          className="new_bot_footer_button"
          acuikit
          onClick={() => saveAndNext()}
        >
          Next
        </ButtonComponent>
      </div>
    </>
  );
};
