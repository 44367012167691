const openAi = (options = {}) => {
    const { ARCHITECT_URL, DEFAULT_API_ERROR } = options;

    const openAiConnect = async (botId, settings) => {
        console.log('openAiConnect', { botId, settings });
        if (!botId) throw new Error('Please specify the id of the agent');

        const url = new URL(`${ARCHITECT_URL}/v1/openai/settings?botId=${botId}`);

        const options = {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            // credentials: 'include',
            method: 'POST',
            body: JSON.stringify(settings),
        };

        try {
            const response = await fetch(url, options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };
            if (!response.ok) {
                throw ({
                    statusCode, message: DEFAULT_API_ERROR, ...body.error, rawBodyTaken: body,
                });
            }
            if (body && body.error) throw ({ statusCode, message: DEFAULT_API_ERROR, ...body.error });
            return true;
        } catch (error) {
            throw (error.statusCode ? error : { message: DEFAULT_API_ERROR });
        }
    };
    return ({
        openAiConnect,
    });
};

export default openAi;
